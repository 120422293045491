import React from 'react'

function Header() {
  return (
    <div>
      <header id="header-sticky" className="header-1 bg-transparent sticky-header1">
  <div className="container">
    <div className="mega-menu-wrapper">
      <div className="header-main style-2">
        <div className="header-left">
          <div className="logo">
            <a href="/" className="header-logo">
              <img src="/wyse_logo_png.png" style={{height:'120px',width:'100px'}} alt="logo-img" />
            </a>
          </div>
        </div>
        <div className="header-right d-flex justify-content-end align-items-center">
          <div className="mean__menu-wrapper">
            <div className="main-menu">
              <nav id="mobile-menu">
                <ul>
                  <li className="has-dropdown active menu-thumb">
                    <a href="/">
                      Home
                      {/* <i className="fas fa-angle-down" /> */}
                    </a>
                    {/* <ul className="submenu has-homemenu">
                      <li>
                        <div className="homemenu-items">
                          <div className="homemenu">
                            <a href="index.html" className="homemenu-thumb">
                              <img
                                src="assets/img/header/home-1.jpg"
                                alt="img"
                              />
                              <span className="demo-button">
                                <span className="theme-btn p1-bg box-style first-box">
                                  <span className="black">Home 01</span>
                                </span>
                              </span>
                            </a>
                          </div>
                          <div className="homemenu">
                            <a
                              href="index2.html"
                              className="homemenu-thumb mb-15"
                            >
                              <img
                                src="assets/img/header/home-2.jpg"
                                alt="img"
                              />
                              <span className="demo-button">
                                <span className="theme-btn p1-bg box-style first-box">
                                  <span className="black">Home 02</span>
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    <a href="#">
                      Services
                      {/* <i className="fas fa-angle-down" /> */}
                    </a>
                    {/* <ul className="submenu">
                      <li>
                        <a href="service.html">Services</a>
                      </li>
                      <li>
                        <a href="service-details.html">Services Details</a>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    <a href="#">
                      Projects
                      {/* <i className="fas fa-angle-down" /> */}
                    </a>
                    {/* <ul className="submenu">
                      <li>
                        <a href="project.html">Project</a>
                      </li>
                      <li>
                        <a href="project-details.html">Project Details</a>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    <a href="#">
                      Blog
                      {/* <i className="fas fa-angle-down" /> */}
                    </a>
                    {/* <ul className="submenu">
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                    </ul> */}
                  </li>
                  <li className="has-dropdown">
                    <a href="#">
                      Pages
                      {/* <i className="fas fa-angle-down" /> */}
                    </a>
                    {/* <ul className="submenu">
                      <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li>
                        <a href="doctor.html">Doctor</a>
                      </li>
                      <li>
                        <a href="doctor-details.html">Doctor Details</a>
                      </li>
                      <li>
                        <a href="pricing.html">Pricing</a>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    <a href="/Contact">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <a
            href="index2.html#0"
            className="search-trigger search-icon d-xl-none d-block"
          >
            <i className="fal fa-search" />
          </a>
          <div className="header__hamburger d-xl-none my-auto">
            <div className="sidebar__toggle">
              <img src="assets/img/icon/menu.png" alt="icon" />
            </div>
          </div>
        </div>
        <div className="header-btn d-xl-block d-none">
          <a
            href="/Contact"
            className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100"
          >
            Contact us
            <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
          </a>
        </div>
      </div>
    </div>
  </div>
</header>

    </div>
  )
}

export default Header
