import { Routes, Route, Navigate } from 'react-router-dom';
import Contact from './Components/Contact';
import Home from './Components/Home';

function Routers() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Contact" element={<Contact />} />
        </Routes>
    );
}

export default Routers;
