import React,{useState} from 'react'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const { name, email, phone, subject, message } = formData;

  if (!name || !email || !phone || !subject || !message) {
    toast.error('All fields are required!');
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    toast.error('Please enter a valid email address.');
    return;
  }

  if (subject.length < 3) {
    toast.error('Subject must be at least 3 characters long.');
    return;
  }

  if (message.length < 10) {
    toast.error('Message must be at least 10 characters long.');
    return;
  }

    toast.loading('please wait while sending Message',{
      duration:400
    })
    try {
      const response = await axios.post('https://wyse.health/api/form', formData);
      console.log(response);
      toast.success('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        phone:'',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form. Please try again.');
    }
  };
  return (
    <div>
      <Header/>
      <>
      <Toaster position='top-center' /> 

  <section className="breadcrumb-section position-relative fix">
    <div className="container">
      <div className="bread-content px-3 d-flex flex-wrap gap-3 align-items-center justify-content-md-between justify-content-center">
        <h2 className="black">DNA Scientist</h2>
        <ul className="d-flex align-items-center gap-3">
          <li>
            <a href="/">Home</a>
          </li>
          <li>/</li>
          <li>Scientist</li>
        </ul>
      </div>
    </div>
    {/* Bread Ele */}
    <img
      src="assets/img/about/breadcrumnd-shap.png"
      alt="img"
      className="bread-ele"
    />
  </section>
  {/* Banner Section Start */}
  {/* Contact Section Start */}
  <section className="contact-section section-padding fix">
    <div className="container">
      <div className="space-bottom">
        <div className="row g-xl-6 g-4 contact-info-area">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="contact-info wow fadeInUp" data-wow-delay="0.6s">
              <div className="icon">
                <i className="fa-solid fa-location-dot" />
              </div>
              <div className="cont">
                <h4 className="fw-bold black d-block mb-1">Address</h4>
                <a href="mailto:Company@mail.com" className="pra fs-seven">
                  1740,Chennai,Tamilnadu,India
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="contact-info wow fadeInUp" data-wow-delay="0.6s">
              <div className="icon">
                <i className="fa-solid fa-envelope" />
              </div>
              <div className="cont">
                <h4 className="fw-bold black d-block mb-1">Email</h4>
                <a href="mailto:Company@mail.com" className="pra fs-seven">
                  wyseglobal@gmail.com <br />
                  wysehealth@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="contact-info wow fadeInUp" data-wow-delay="0.6s">
              <div className="icon">
                <i className="fa-solid fa-phone" />
              </div>
              <div className="cont">
                <h4 className="fw-bold black d-block mb-1">Phone</h4>
                <a href="mailto:Company@mail.com" className="pra fs-seven">
                  017 5552-0127 <br />
                  017458632718
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-wrapper-anys white-bg rounded-4">
        <div className="row g-6 align-items-center justify-content-between">
          <div className="col-lg-7">
            <form
onSubmit={handleSubmit}            
  className="appoentment-forms py-xl-0 py-6 px-xl-10 px-6"
            >
              <div className="section-title mb-60">
                <span className="black fw_700 fs-six d-block mb-3 heading-font">
                  Contact Us
                </span>
                <h2
                  className="wow fadeInUp black visible-slowly-right"
                  data-wow-delay=".3s"
                >
                  Get an
                  <span className="position-relative z-1">
                    Appiontment
                    <img
                      src="assets/img/element/title-badge1.png"
                      alt="img"
                      className="title-badge1 d-md-block d-none w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="row g-lg-4 g-3">
                <div className="col-lg-6">
                  <input type="text" name='name'  value={formData.name}
                  onChange={handleChange} placeholder="Your Name" />
                </div>
                <div className="col-lg-6">
                  <input type="email"  name='email'  value={formData.email}
                  onChange={handleChange} placeholder="Your Email" />
                </div>
                <div className="col-lg-6">
                  <input type="text"  name='phone'  value={formData.phone}
                  onChange={handleChange} placeholder="Phone Number" />
                </div>
                <div className="col-lg-6">
                  <input type="text"  name='subject'  value={formData.subject}
                  onChange={handleChange} placeholder="Subject" />
                </div>
                <div className="col-lg-12">
                  <textarea
                    name="message"
                     value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    rows={5}
                    defaultValue={""}
                  />
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="common-btn box-style p2-bg w-100 text-nowrap d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden rounded100 wow fadeInRight"
                    data-wow-delay="0.8s"
                  >
                    Book An Appiontment
                    <img
                      src="assets/img/icon/arrow-right-white.png"
                      alt="icon"
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-5">
            <div className="contact-thumb w-100">
              <img
                src="https://static.vecteezy.com/system/resources/previews/041/642/113/non_2x/ai-generated-portrait-of-young-doctor-man-happy-smiling-free-png.png"
                alt="img"
                className="rounded-4 w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Map */}
  {/* <div className="space-bottom">
    <div className="map-area">
      <div className="container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52816169.558200695!2d-161.49265223136007!3d36.102185713814805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1726005337075!5m2!1sen!2sbd"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  </div> */}
</>

      <Footer/>
    </div>
  )
}

export default Contact
